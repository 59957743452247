import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import Web3 from "web3";
import axios from "axios";
import {
  ACTIVE_NETWORK,
  default_RPC_URL,
  FeeCollector,
  NetworkDetails,
} from "../constants/";
import FeeCollectorABI from "src/abis/FeeCollectorABI.json";

export const mulltiCallAddressFiero =
  "0xDC32ce73f6Ff2f784c0c1Ab8a212eC9151f21179";

export function sortAddress(add) {
  if (add) {
    const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
    return sortAdd;
  } else {
    return add;
  }
}
export function sortAddress1(add) {
  if (add) {
    const sortAdd = `${add.slice(0, 10)}...`;
    return sortAdd;
  } else {
    return add;
  }
}
//-----this  copy from juan
export const validateAccountAddress = async (account) => {
  try {
    const web3 = await getWeb3Obj();
    const accountCheck = web3.utils.toChecksumAddress(account);
    if (accountCheck !== "") {
      return true;
    }
  } catch (error) {
    return false;
  }
};
export function fromWeiDecimals(balanceOfWei, decimals) {
  if (balanceOfWei) {
    const balanceOfFromWei12 = ethers.utils.formatUnits(
      balanceOfWei.toString(),
      decimals
    );
    return balanceOfFromWei12;
  } else {
    return balanceOfWei;
  }
}
export function toWeiDecimals(balanceOfWei, decimals) {
  if (balanceOfWei) {
    const balanceOfFromWei = ethers.utils.parseUnits(
      balanceOfWei.toString(),
      decimals
    );
    return balanceOfFromWei.toString();
  } else {
    return balanceOfWei;
  }
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getAllowanceCheck = async (
  address,
  ABI,
  library,
  account,
  contractTocheck
) => {
  let contract = await new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account)
  );
  const allowanceallowance = await contract.allowance(account, contractTocheck);
  return allowanceallowance.toString();
};
export const getWeb3Obj = async (RPC_URL = default_RPC_URL) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = async (
  abi,
  contractAddress,
  RPC_URL = default_RPC_URL
) => {
  const web3 = await getWeb3Obj(RPC_URL);
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export function copyTextById(id) {
  var copyText = document.getElementById(id);
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  navigator.clipboard.writeText(copyText.value);
  toast.info(`Copied ${copyText.value}`);
}

export const swichNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
    });
  } catch (error) {
    console.log("ERROR", error);
    toast.warn(error.message);
    if (error.code === 4902) {
      addNetworkHandler();
    }
  }
};

export const addNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails,
    });
  } catch (error) {
    console.log("ERROR", error);
    toast.warn(error.message);
  }
};

export const BinencecoinUSDPrice = async () => {
  try {
    const getWethPriceInDollor = await axios.get(
      `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin`,
      {
        // params: {
        //   module: "gastracker", //
        //   action: "gasoracle", //
        //   apikey: "D6BJ2PSTWXGWNGT86SIPDBFTRZPBK5P35H", //
        // },
      }
    );
    let SafeGasPrice = getWethPriceInDollor.data[0].current_price;

    return SafeGasPrice;
  } catch (error) {
    console.log("----cross-exchange----- 217 <<<<<< error >>>>>>", error);
  }
};

export const getTokenAddressDetails = async (amount, account, library) => {
  try {
    const contract = getContract(
      FeeCollector,
      FeeCollectorABI,
      library,
      account
    );
    const distributionContract = await contract.distributeFee({
      value: amount,
    });

    await distributionContract.wait();
    return distributionContract;
    // return true;
  } catch (error) {
    console.log(error, "x error x", error.message);
    // toast.warn(error.message);
    if (error.data) {
      toast.error(error.data.message);
    } else {
      toast.warn(error.message.split("(")[0]);
    }
    return false;
  }
};
export function ToFixedWithZero(amount) {
  try {
    var decimalString = amount.toString();
    var decimalPointIndex = decimalString.indexOf(".");
    var zeroCount = 0;
    // if (decimalString != "0") {
    for (var i = decimalPointIndex + 1; i < decimalString.length; i++) {
      if (decimalString[i] === "0") {
        zeroCount++;
      } else {
        break;
      }
    }
    // } else {
    //   zeroCount = 0;
    // }
    // console.log(zeroCount, " decimalString", decimalString);
    return Number(amount).toFixed(Number(zeroCount + 1));
  } catch (err) {
    console.log(" err", err);
    return 0;
  }
}

export function changeExtenstion(str) {
  const checkFormat1 = str.includes(".png");
  const checkFormat2 = str.includes(".jpg");
  const checkFormat3 = str.includes(".jpeg");
  const checkFormat4 = str.includes(".gif");
  const checkFormat5 = str.includes(".webp");

  if (checkFormat1) {
    return str.replace(".png", ".webp");
  }
  if (checkFormat2) {
    return str.replace(".jpg", ".webp");
  }
  if (checkFormat3) {
    return str.replace(".jpeg", ".webp");
  }
  if (checkFormat4) {
    return str.replace(".gif", ".webp");
  }
  if (checkFormat5) {
    return str;
  }
}
