import Web3 from "web3";
export const NetworkContextName = "Fiers Mainnet";
export const ACTIVE_NETWORK = 1001;
export const deadAddress = "0x0000000000000000000000000000000000000000";
export const deadAddressDead = "0x000000000000000000000000000000000000dEaD";
/*****************Admin---------Wallet---------Address*****************/
export const AdminOwner = "0xA49C3De8D6218f8ec1a5dFDe2fE905E4e7096060";
export const serviceFee = "0.00001";
export const DividentTokenAddress =
  "0x5E7D6101a3b530A863663e3B18f138e6750cddC3"; // ---------------- NOT CHANGE
export const textDeadAddress =
  "0x0000000000000000000000000000000000000000000000000000000000000000";

// ---------- LIVE --------------

export const liquidityLockerAddress =
  "0xc483e59c33F2A85256C6Bc67b862458c40860040"; //live ---------
export const factoryContractAdress =
  "0x47a56C912437502D5cBc57933b36c22165730160";
export const multiSenderAddress = "0x4e693AA6014463df780854E2a123B91E87de5138"; //live ---------
export const mlmContractAddress = "0xdB5260BBE1f1342e98DBaA8D39e93cA6cf3343ea"; //live ---------
export const ShieldexStakingContracAddress =
  "0xcE94BCb6F82dDe85a895591eA9a1830F086eecF7"; // live
export const FieroDexRouter = "0xFF60b7e9C8512C7202B23cE3e9402153B282EE71"; // LIVE
export const ammFactory = "0x2f0B57005d3aFC96FABD1aA0C303345D1754F6ff"; // LIVE
export const wfieroAddress = "0x8dA89E0901295b38820B201D7e32A124d68B5ca7"; /// LIVE
export const FeeCollector = "0xC0b483e5B6CAd399Dbf66EafdC6F49aF0291676E"; // live

//
// export const factoryContractAdress =
//   "0x87A6eE0b9bEDBEE8a2aEC7EFCAC5Dc2a63DB5969"; //TEST
// export const liquidityLockerAddress =
//   "0x6D0A4D04Ad7B2c92c95987c874aE62B849f4f306"; //test
// export const multiSenderAddress = "0xC1FAe163DE2b8B0fa9df3C9e94f52021B4f3B5f7"; //test
// export const mlmContractAddress = "0x24b04DDa846407115de3B1B79FA03b63954846b4"; //test
// export const FieroDexRouter = "0x8F547a4a9F53BcB216A517b8Ff78B084fbAE9328"; // TEST
// export const ammFactory = "0xcB0637b241F155BC8A6C48030f96FabdFd17368F"; // TEST
// export const wfieroAddress = "0x4dC65321dF987EBAa5Dd5797aC2F44c274e38FcE"; // TEST
// export const ShieldexStakingContracAddress =
//   "0x70f8Ffa197dfAFd1131816Ba7c5c988739BDFB12"; // test

// export const FeeCollector = "0xB62E59a6dC70DBD293483Ac7Ae90Ad84A9b003Ad"; // --------------

export const BUSDTokenAddress = "0x21783C0Ce32e1859F6bccC6e575Ae6019765e443";
export const USDTTokenAddress = "0x54f6719302106a7462cbea1318b515b2d3ec8456";

export const maxPromote = 10;
export const RPC_URL = "https://rpc1.fieroscan.com/"; //mainNet    /////-----this

export const default_RPC_URL = RPC_URL;
export const explorerURL = "https://fieroscan.com";

export const tokenCreatorFees = "10000000000000";

export const NetworkDetails = [
  {
    chainId: "0x3E9",
    chainName: "Fiers Mainnet",
    nativeCurrency: {
      name: "FIERO",
      symbol: "FIERO",
      decimals: 18,
    },
    rpcUrls: [default_RPC_URL],
    blockExplorerUrls: [explorerURL],
  },
];

export const getWeb3Obj = async (RPC_URL = default_RPC_URL) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = async (abi, contractAddress, RPC_URL) => {
  const web3 = await getWeb3Obj(RPC_URL);
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};
export const getPromoteFees = (days) => {
  // eslint-disable-next-line
  switch (days.toString()) {
    case "1":
      return 0.001;
    case "2":
      return 0.002;
    case "3":
      return 0.003;
  }
};

export const poolData = [
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "Rise Of Defenders - GA",
    text2: "RDR / BUSD",
    maxBusd: "49.99968",
    access: "BSL",
    progress: "100.00%",
    participants: "205",
    text3: "277778/277778",
    ratio: "27.77778 RDR",
  },
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "Rise Of Defenders - BSL",
    text2: "RDR / BUSD",
    maxBusd: "2749.968",
    access: "BSL",
    progress: "100.00%",
    participants: "356",
    text3: "2500000/2500000",
    ratio: "27.77778 RDR",
  },
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "SnapEx — BSL",
    text2: "RDR / BUSD",
    maxBusd: "5225",
    access: "BSL",
    progress: "100.00%",
    participants: "480",
    text3: "80000000/80000000",
    ratio: "40 SNAP",
  },
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "Rise Of Defenders - GA",
    text2: "RDR / BUSD",
    maxBusd: "49.99968",
    access: "BSL",
    progress: "100.00%",
    participants: "205",
    text3: "277778/277778",
    ratio: "27.77778 RDR",
  },
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "Rise Of Defenders - BSL",
    text2: "RDR / BUSD",
    maxBusd: "2749.968",
    access: "BSL",
    progress: "100.00%",
    participants: "356",
    text3: "2500000/2500000",
    ratio: "27.77778 RDR",
  },
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "SnapEx — BSL",
    text2: "RDR / BUSD",
    maxBusd: "5225",
    access: "BSL",
    progress: "100.00%",
    participants: "480",
    text3: "80000000/80000000",
    ratio: "40 SNAP",
  },
];
