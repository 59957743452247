import defaultTheme from "./default";

import { createMuiTheme } from "@material-ui/core";

const overrides = {
  typography: {
    h1: {
      fontSize: "41px",
      fontWeight: "600",
      fontFamily: "Good Times Rg",
    },
    h2: {
      fontSize: "32px",
      fontWeight: "600",
      fontFamily: "Good Times Rg",
      "@media(max-width:767px)": {
        fontSize: "26px !important",
        lineHeight: "30px !important",
      },
    },
    h3: {
      fontSize: "25px",
      fontFamily: "'Sora', sans-serif",
      "@media(max-width:767px)": {
        fontSize: "20px !important",
      },
    },
    h4: {
      fontSize: "20px",
      fontFamily: "'Sora', sans-serif",
    },
    h5: {
      fontSize: "18px",
      fontFamily: "'Sora', sans-serif",
    },
    h6: {
      fontSize: "15px",
      fontFamily: "'Sora', sans-serif",
    },
    body1: {
      fontSize: "12px",
      fontFamily: "'Sora', sans-serif",
    },
    body2: {
      fontSize: "16px",
      fontFamily: "'Sora', sans-serif",
    },
  },
};

const themes = {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};

export default themes;
