import tinycolor from "tinycolor2";
const primary = "#05cfc2";
const secondary = "rgba(255, 255, 255, 0.03)";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";
const lightenRate = 7.5;
const darkenRate = 15;
const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
  },
  overrides: {
    // MuiSelect: {
    //   icon: {
    //     color: "#fff",
    //   },
    // },
    MuiLinearProgress: {
      bar1Determinate: {
        width: "100%",
      },
    },
    MuiCheckbox: {
      root: {
        color: "#05cfc2",
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "14px",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
        paddingTop: "0px !important",
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    // MuiFormHelperText:{
    //   error:{
    //     paddingTop:"0px !important"
    //   }
    // },
    MuiPaginationItem: {
      page: {
        "&.Mui-selected": {
          backgroundColor: "rgb(254 84 147)",
          color: "#fff",
        },
      },
    },
    MuiPopover: {
      paper: {
        background: "#292929",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#ffff",
      },
      dayDisabled: {
        color: "#b9b9b95e",
      },
    },
    MuiSwitch: {
      colorPrimary: {
        "&.Mui-disabled + .MuiSwitch-track": {
          backgroundColor: " #a69999 !important",
          cursor: "not-allowed",
        },
        "&.Mui-disabled": {
          color: "#525050",
        },
      },
      track: {
        backgroundColor: "#b2b2b2",
      },
    },

    PrivateValueLabel: {
      label: {
        color: "#fff",
      },
    },
    MuiListItem: {
      root: {
        width: "100%",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        textAlign: "left",
        alignItems: "center",
        paddingTop: "0px",
        paddingBottom: "0px",
        justifyContent: "flex-start",
        textDecoration: "none",
      },
      gutters: {
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "rgba(0, 0, 0, 0.54)",
        padding: "5px",
        overflow: "visible",
        fontSize: "1.5rem",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "0 !important",
        },
      },
    },
    MuiStepIcon: {
      text: {
        fill: "#fff",
        // color: "#FA056C",
      },
      root: {
        color: "rgb(255 255 255 / 38%)",
        MuiStepIconActive: {
          color: "#fff",
        },
        "&.MuiStepIcon-completed": {
          color: "#ff306d",
        },
        "&.MuiStepIcon-active": {
          // background:
          //   "linear-gradient(144.97deg, #FF564D 15.2%, #FF0098 103.79%)",
          background: "url(images/mlm/circlebg.svg)",
          backgroundSize: "cover",
          color: "transparent",
          "&.text": {
            fill: "#FA056C",
          },
        },
      },
    },

    MuiTableRow: {
      root: {
        color: "inherit",
        display: "table-row",
        outline: "0",
        verticalAlign: "middle",
        // background: "#e9c3eb",
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#FF009840",
        color: "#fff",
        fontWeight: "500",
        lineHeight: "1.5rem",
      },
      alignRight: {
        textAlign: "center",
        flexDirection: "row-reverse",
      },
      body: {
        color: "#fafafa",
      },
      root: {
        display: "table-cell",
        padding: "13px",
        fontSize: "0.875rem",
        textAlign: "center",
        fontWeight: "400",
        lineHeight: "1.43",
        borderBottom: "1px solid1px solid rgba(255, 255, 255, 0.05) !important",
        letterSpacing: "0.01071em",
        verticalAlign: "inherit",
      },
    },
    MuiDialogActions: {
      root: {
        background: "#1A1717 !important",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "450px",
      },
      paper: {
        background: "#1A1717",
      },
    },
    MuiTab: {
      root: {
        padding: "6px 12px",
        overflow: "hidden",
        position: "relative",
        fontSize: "16px",
        fontWeight: "bold",
        maxWidth: "264px",
        minWidth: "72px",
        boxSizing: "border-box",
        minHeight: "48px",
        textAlign: "center",
        lineHeight: "1.75",
        whiteSpace: "normal",
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        "@media (max-width: 767px)": {
          fontSize: "13px",
        },
      },
    },

    MuiSlider: {
      root: {
        // color: "rgb(7 6 6) !important",
        width: "100%",
        cursor: "pointer",
        height: "8px",
        display: "inline-block",
        padding: "13px 0",
        position: "relative",
        boxSizing: "content-box",
        touchAction: "none",
      },
      thumb: {
        backgroundColor: "white !important",
      },
      rail: {
        color: "rgba(255, 255, 255, 0.15)",
      },
      track: {
        // color: "#0BD8CB",
      },
      mark: {
        width: "15px",
        color: "#0e0a0c",
      },
      markActive: {
        width: "7px",
        opacity: 0,
        borderRadius: 0,
        backgroundColor: "#0e0a0c",
        padding: "6px",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          display: "none",
        },
        "&::before": {
          display: "none",
        },
      },
    },
    MuiInputBase: {
      fullWidth: {
        width: "100%",
        borderBottom: "1px solid #8080808f",
      },
      input: {
        font: "inherit",
        color: "#FFFFFF99",
        border: "none",
        width: "100%",
        borderRadius: "50px",
        fontSize: "13px",
        height: "1em",
        margin: "0",
        display: "block",
        padding: "7px 0 7px",
        minWidth: "0",
        background: "transparent",
        boxSizing: "content-box",
        letterSpacing: "inherit",
      },
      multiline: {
        padding: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#05CFC2",
        height: "30px",
        width: "30px",
        display: "inline-block",
        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        flexShrink: "0",
        userSelect: "none",
        marginRight: "3px",
      },
    },
    MuiAppBar: {
      positionFixed: {
        zIndex: 1,
      },
      root: {
        width: "100%",
        display: "flex",
        boxSizing: "border-box",
        flexShrink: "0",
      },
      colorPrimary: {
        color: "#fff",
        backgroundColor: "#15161C",
      },
    },
    MuiMenuItem: {
      root: {
        color: "#B8B8B8",
        borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
        padding: "10px 0",
      },
    },
    MuiPaper: {
      root: { boxShadow: "none !important" },
      rounded: {
        backgroundColor: "rgba(255, 255, 255, 0.025)",
        // width: "100%",
        // border: "1px solid #9b41a175",
        borderRadius: "5px",
      },
    },

    MuiStepper: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
        border: "0.25px solid #9b41a12b",
        borderRadius: "10px",
        "@media (max-width: 767px)": {
          display: "block",
        },
      },
    },
    MuiStep: {
      horizontal: {
        width: "25%",
        "@media (max-width: 767px)": {
          width: "100%",
        },
      },
    },
    MuiButton: {
      text: {
        padding: "0px 8px",
      },
      containedSecondary: {
        // color: "#fff",
        cursor: "pointer",
        fontSize: "14px",
        minWidth: "64px",
        fontWeight: "600",
        borderRadius: "50px",
        padding: "10px 35px",
        lineHeight: "21px",
        color: "rgba(255, 255, 255, 0.6)",
        background: "rgba(255, 255, 255, 0.03)",
        textTransform: "capitalize",
        border: "1px solid",
        borderColor: "rgb(255 255 255 / 17%)",
        "&:hover": {
          border: "1px solid",
          borderColor: "transparent",
          background: "#000",
          backgroundImage:
            "linear-gradient(#FF6F37,  #FF2676  ),linear-gradient(247deg, #FF6F37, #B801AA) !important",
          backgroundOrigin: "border-box",
          backgroundClip: "content-box, border-box",
          boxShadow: "2px 1000px 1px #050706 inset !important",
        },
      },
      outlinedPrimary: {
        color: " #9b41a1",
        border: "1px solid #9b41a1",
        borderRadius: "50px",
        fontSize: "14px !important",
        lineHeight: "16px",
        height: " 35px",
        fontWeight: "600",
        maxWidth: "153px",
        textTransform: "capitalize",
        "@media (max-width:767px)": {},
        "&:hover": {
          color: "#FFFFFF",
          backgroundColor: "#9b41a1",
          border: "1px solid #9b41a1",
        },

        "&.Mui-disabled": {
          cursor: "not-allowed !important",
          backgroundColor: "#2a1926 !important ",
          border: "1px solid #ffffff14",
        },
      },
      outlinedSecondary: {
        color: "#ffffff",
        border: "1px solid #fff",
        borderRadius: "50px",
        "&:hover": {
          color: "#FFFFFF",
          backgroundColor: "#9b41a1",
          border: "1px solid #9b41a1",
        },
      },
      root: {
        "&:hover": {
          backgroundColor:
            "linear-gradient(180deg, rgba(248,185,0,1) 16%, rgba(143,84,9,1) 79%)",
        },
        "&.Mui-disabled": {
          cursor: "not-allowed",
          color: "#FFFFFF99 !important ",
        },
      },
      containedSizeLarge: {
        padding: "12px 22px",
      },
      contained: {
        "&:hover": {
          backgroundColor: "linear-gradient(180deg, #F8B900 0%, #8F5409 100%)",
        },
        "&.Mui-disabled": {
          cursor: "not-allowed",
          color: "rgb(219 219 219 / 48%) !important",
          background: "rgba(0, 0, 0, 0.12) !important",
        },
      },
      containedPrimary: {
        borderRadius: "50px",
        fontSize: "14px",
        fontWeight: 600,
        color: "#fff",
        transition: "0.5s",
        backgroundColor: "#9b41a1",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#9b41a1",
        },
      },
    },
    MuiOutlinedInput: {
      inputMultiline: {
        padding: "18.5px 14px",
        color: "#FFFFFF99 !important",
      },
      adornedEnd: {
        paddingRight: 0,
      },
      root: {
        borderRadius: "10px",
        "&:focus-visible": {
          border: "1px solid transparent !important",
          outlineColor: "none",
        },
      },
      multiline: {
        padding: "0px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "none",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: "20px",
        marginTop: "20px",
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
      list: {
        padding: "5px 16px",
      },
    },
    MuiSelect: {
      selectMenu: {
        width: "100%",
        borderRadius: "10px",
        fontSize: "13px",
        margin: "0",
        padding: "5px 12px 7px",
        // height: "30px",
        display: "flex",
        alignItems: "center",
        borderColor: "#9B41A1 !important",
      },
      select: {
        "&:focus": {
          borderRadius: "5px",
          background: "rgba(255, 255, 255, 0.025) !important",
        },
      },
      icon: {
        color: "#000000",
      },
      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        // color: "#FFFFFF99 !important ",
      },
    },
    MuiPickersModal: {
      dialogRootWider: {
        backgroundColor: "#141112",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#3a055b",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "#ffffff8f",
      },
      toolbarBtnSelected: {
        color: "#ffffff",
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#3a055b",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 0,
      },
    },
  },
};

export default defaultTheme;
